import React from 'react';
import { Formik, Field } from 'formik';
import DatePicker from 'react-datepicker';
import styled from '@emotion/styled';
import {
  Button,
  TextField,
  TextAreaField,
  CheckboxField,
  SelectField,
} from '@aws-amplify/ui-react';
import TextEditor from '../TextEditor';
import 'react-datepicker/dist/react-datepicker.css';
import { getImageUrl } from '../../utils';
import FaqBlock from '../FaqBlock';
import MultiSelect from '../MultiSelect';

const ContainerForm = styled('form')`
  display: grid;
  max-width: 1200px;
  width: 100%;
  margin: 30px auto;
  column-gap: 10px;
  row-gap: 50px;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'sb ed'
    'faq faq'
    'btn btn';
`;
const FormBar = styled('div')`
  grid-area: sb;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  & > div,
  textarea {
    width: -webkit-fill-available;
  }
  & > button {
    align-self: center;
    margin-top: 50px;
  }
`;
const EditorContainer = styled('div')`
  grid-area: ed;
`;
const MediaButtons = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

const MediaContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  raw-gat: 10px;
`;
const SubmitButton = styled.div`
  grid-area: btn;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FaqContainer = styled.div`
  grid-area: faq;
  margin: auto;
  max-width: 800px;
  width: 100%;
`;
const kebabCase = (string) => string
  .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
  .map((x) => x.toLowerCase())
  .join('-');

function PostForm({
  onSubmit,
  onFeaturedImageChange,
  id,
  initialValues,
  onImageChange,
  onImageDelete,
}) {
  const [featureImgSrc, setNewFeatureImgSrc] = React.useState(
    initialValues.featuredImageUrl,
  );
  const [faqs, setFaqs] = React.useState(initialValues.faq || []);
  const [selectedTags, setSelectedOptions] = React.useState(initialValues.tags || []);
  const options = ['Music', 'Accordion', 'News', 'Learning', 'History', 'Entertainment', 'Events', 'Experts', 'Other'];
  const handleFeaturedImageChange = (event, formProps) => {
    const file = event.currentTarget.files[0];
    formProps.setFieldValue('featuredImage', file.name);
    onFeaturedImageChange(event);
    setNewFeatureImgSrc(URL.createObjectURL(file));
  };

  const handleMediaChange = (e) => {
    onImageChange(e);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          let changedHandle;
          if (values.handle === '') {
            changedHandle = kebabCase(values.title);
          } else {
            changedHandle = kebabCase(values.handle);
          }
          const changeValues = {
            ...values,
            handle: changedHandle,
            tags: selectedTags,
            faq: faqs,
          };
          onSubmit(changeValues, id);
          resetForm();
        }}
      >
        {(props) => (
          <ContainerForm onSubmit={props.handleSubmit}>
            <FormBar>
              <TextField
                type="text"
                onChange={props.handleChange}
                value={props.values?.title}
                name="title"
                placeholder="Post title"
                isRequired
                label="Title"
              />
              <TextField
                type="text"
                onChange={props.handleChange}
                value={props.values.author}
                name="author"
                placeholder="Post author"
                label="Author"
              />
              <TextAreaField
                name="exerpt"
                onChange={props.handleChange}
                value={props.values.exerpt}
                placeholder="Brief summary of the post/preview description"
                label="Summary/Preview description"
              />

              {initialValues.blogPostsId
              === 'af1362ec-bb07-46a8-8cee-46bf56c9ae9e' ? (
                <SelectField
                  label="Post category"
                  placeholder="Please  choose post category"
                  onChange={props.handleChange}
                  value={props.values.categories}
                  name="categories"
                >
                  <option value="WEBDEV">Web development</option>
                  <option value="SEOPROMOTION">SEO promotion</option>
                  <option value="MARKETINGAUTOMATION">
                    Marketing automotion
                  </option>
                  <option value="PPCSERVICES">PPC services</option>
                  <option value="GRAPHICDESIGN">Graphic design</option>
                  <option value="SOCIALMEDIA">Social media</option>
                  <option value="CONTENTPRODUCTION">Content production</option>
                </SelectField>
                ) : (
                  <>
                    <div> Tags/categories </div>
                    <MultiSelect
                      options={options}
                      selectedOptions={selectedTags}
                      setSelectedOptions={setSelectedOptions}
                      name="tags"
                      id="tags"

                    />
                  </>
                )}
              <TextField
                type="number"
                onChange={props.handleChange}
                value={props.values.duration}
                name="duration"
                placeholder="Duration (in minutes)"
                label="Duration (in minutes):"
              />
              <TextField
                type="text"
                onChange={props.handleChange}
                value={props.values.seoTitle}
                name="seoTitle"
                placeholder="SEO title"
                label="SEO title"
              />
              <TextAreaField
                type="text"
                onChange={props.handleChange}
                value={props.values.seoDescription}
                name="seoDescription"
                placeholder="SEO description"
                label="SEO description"
              />
              <TextField
                name="handle"
                onChange={props.handleChange}
                value={props.values.handle}
                placeholder="handle"
                label="Handle (the last part of the url)"
              />
              <Field
                type="checkbox"
                name="visibility"
                as={CheckboxField}
                label={props.values.visibility ? 'Post visible' : 'Post hidden'}
              />
              <DatePicker
                name="publishAt"
                value={props.values.publishAt}
                selected={
                  (props.values.publishAt
                    && new Date(props.values.publishAt))
                  || null
                }
                onChange={(e) => {
                  props.setFieldValue('publishAt', e);
                }}
                label="Publish at:"
              />
              <span>Post main image:</span>
              {featureImgSrc && (
                <img
                  src={featureImgSrc}
                  alt="featured"
                  width="200px"
                  height="auto"
                />
              )}
              <input
                id="featuredImage"
                name="featuredImage"
                type="file"
                onChange={(event) => handleFeaturedImageChange(event, props)}
                label="Post main image"
                accept="image/png, image/jpeg, image/jpg"
              />
              {id && (
                <>
                  <span>Post media:</span>
                  <div>
                    {initialValues.media
                      && initialValues.media.map((fileName) => {
                        const imgUrl = getImageUrl(fileName);
                        const isFileInUse = props.values.content.includes(imgUrl);
                        return (
                          <MediaContainer key={fileName}>
                            <img
                              src={imgUrl}
                              alt="media"
                              width="200px"
                              height="auto"
                            />
                            <MediaButtons>
                              <Button
                                onClick={() => {
                                  if (!isFileInUse) {
                                    onImageDelete(fileName);
                                  }
                                }}
                                type="button"
                                disabled={isFileInUse}
                                color="#af3030"
                              >
                                Delete
                              </Button>
                              <Button
                                color="var(--amplify-components-button-primary-background-color)"
                                onClick={() => {
                                  const contentValue = `${props.values.content}<img src=${imgUrl} width="500px" height="auto" />`;
                                  props.setFieldValue('content', contentValue);
                                }}
                                type="button"
                              >
                                Add to content
                              </Button>
                            </MediaButtons>
                          </MediaContainer>
                        );
                      })}
                  </div>
                  <input
                    type="file"
                    name="media"
                    multiple
                    onChange={handleMediaChange}
                    label="Post media"
                    accept="image/png, image/jpeg, image/jpg"
                  />
                </>
              )}
            </FormBar>
            <EditorContainer>
              <TextEditor
                props={props}
                initialValue={props.values.content}
                name="content"
              />
            </EditorContainer>
            <FaqContainer>
              {' '}
              <FaqBlock value={faqs} onChange={setFaqs} id="faqs" />
            </FaqContainer>
            <SubmitButton>
              {' '}
              <Button variation="primary" size="large" type="submit">
                Submit
              </Button>
            </SubmitButton>
          </ContainerForm>
        )}
      </Formik>
    </div>
  );
}

export default PostForm;
